import React,  { useState }  from 'react';
import './Gallery.css';


const GalleryItem = ({ title, isExpandable, src, link, description, isExpanded, onToggleExpand }) => {
  const handleClick = (e) => {
    /*e.preventDefault();*/
    if (isExpandable) {
      onToggleExpand();
    } else if (link) {
      window.location.href = link;
    }
  };

  return (
    <div 
      className={`gallery-item ${isExpanded ? 'expanded' : ''} ${isExpandable ? 'expandable' : ''}`} 
      onClick={handleClick}
    >
      <div className="gallery-item-content" style={{backgroundImage: `url('${src}')`}}>
        <div className="title">{title}</div>
      </div>
      {isExpanded && (
        <div className="expanded-content">
          <h2>{title}</h2>
          <p>{description || "No description available."}</p>
          {link && <a href={link} rel="noopener noreferrer">See More</a>} {/*target="_blank"*/} 
        </div>
      )}
    </div>
  );
};

const Gallery = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const galleryItems = [
    { title: "Partner Acrobatics", isExpandable: false, src: "./img/project-imgs/acro.png", link: 'http://www.guns-n-poses.idamayer.com/demo-reel.html' },
    { title: "Circus Performances", isExpandable: true, src: "./img/project-imgs/circus.jpg", description: <p>I have been training in various circus arts since 2015, and have performed in venues throughout the Bay Area. <br/><a href="/cv#acro">See previous performances</a><br/> <a href='http://www.guns-n-poses.idamayer.com/gallery.html'>Photo gallery</a><br/> <a href="/classes">Take classes with me</a> </p>  },
    { title: "Neuroscience Research", isExpandable: false, src: "./img/project-imgs/neuro.png", link: '/cv.html#neuro'   },
    { title: "Google Career", isExpandable: false, src: "./img/project-imgs/google.jpg", link: '/cv.html#google'  },
    { title: "Valley of Fire Series", isExpandable: true, src: "./img/project-imgs/valleyoffire.jpg" , link: 'https://www.instagram.com/p/CYKHh7DptFw/', description: 'This is the only modeling project where I undertook the entire artistic direction, styling, location scouting, and editing. During modeling projects I\'ve often been asked whether my goals in modeling are to look glam and beautiful or to create mysterious stories, and I usually answer "I wanna pretend to be a rock". This portrait series in the Valley of Fire, Nevada is the closest I have ever become to being a rock. I worked with photographer Eric Tseng to bring this vision to life. In many ways it is the project I became a model to do.' },
    { title: "Henry Asencio Paintings", isExpandable: true, src: "./img/project-imgs/asencio.jpg", description: <p>It has been one of the highlights of my modeling career to work with the painter <a href='http://asenciostudio.com/gallery/original-figurative-works/'>Henry Asencio</a> to produce paintings valued in the 5 figures that have been displayed across the country. <a href='https://artworldmatch.com/art/aerial-by-henry-asencio'>See current listings</a></p>  },
    { title: "Creative Portraits", isExpandable: false, src: "./img/project-imgs/nude.jpg", description: <p>I've had the oportunity to work with many amazingly talented artists and photographers over the years. You can see some of my favorite work in my <a href='https://www.modelmayhem.com/idamayer'>portfolio</a> or on my <a href="https://instagram.com/idamayeraesthetic">instagram</a></p>, link: 'https://instagram.com/idamayeraesthetic'  },
    { title: "Modern Games Deck", isExpandable: false, src: "./img/project-imgs/cards.jpg" , link: 'https://idamayer.com/moderngamesdeck/' },
    { title: "Za: The Word Game", isExpandable: false, src: "./img/project-imgs/za.png", link: 'https://zathewordgame.wordpress.com/' },
    { title: "Illustrations", isExpandable: false, src: "./img/project-imgs/illustration.png", link: 'https://drive.google.com/drive/folders/1YRjTVnAqyNezzqpXEcGC4ea7dtJfSeCi' },
    { title: "Culinary Projects", isExpandable: true, src: "./img/project-imgs/food.jpg", link: 'https://photos.app.goo.gl/cNJ8bAhFbmmK6fdM6',
        description: "I have made a wide variety of creative foods and co-authored a cookbook."
      },
      { title: "Master's Thesis", isExpandable: false, src: "./img/project-imgs/thesis.jpg", link:'https://docs.google.com/presentation/d/1zt8b2YIOJFXeiIoCm52bD5wF4fiSME-pFo65GBBDv3M/edit?usp=sharing' },
      { title: "Academic Background", isExpandable: false, src: "./img/project-imgs/academic.jpg", link:'/cv.html#edu' },  
    { title: "Chinese", isExpandable: true, src: "./img/project-imgs/chinese.jpg",
        description: "It doesn't make for great photos, but learning spoken mandarin chinese has taken the largest portion of my hobby time of late."
      },
  ];


  return (
    <div id="gallery" className="gallery">
      {galleryItems.map((item, index) => (
        <GalleryItem 
          key={index} 
          title={item.title}
          isExpandable={item.isExpandable}
          src={item.src} 
          link={item.link}
          description={item.description}
          isExpanded={expandedIndex === index}
          onToggleExpand={() => setExpandedIndex(expandedIndex === index ? null : index)}
        />
      ))}
    </div>
  );
};

export default Gallery;
