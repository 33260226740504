import React from 'react';


const NavBar = ({ setCurrentPage }) => {
  return (
    <nav className="top-nav">
      <a href="/">Home</a>
      <a href="/#about">About</a>
      <a href="/#gallery">Projects</a>
      <a href="https://coaching.idamayer.com">Coaching</a>
      <a href="/classes">Classes</a>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfWCwbly-8mHm4v3Ax5FJARczXtxxqZmz1IG6QDleiW1Gd41Q/viewform">Contact</a>
    </nav>
  );
};

export default NavBar;
